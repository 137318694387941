import { createSelector } from "@reduxjs/toolkit";
import { getUi } from "store/store.selectors";

export const getFilter = createSelector([getUi], (ui) => ui.filter);
export const getManufacturerFilter = createSelector(
  [getFilter],
  (filter) => filter.manufacturers
);
export const getProductGroupFilter = createSelector(
  [getFilter],
  (filter) => filter.productGroup
);

export const getSortBy = createSelector([getUi], (ui) => ui.sortBy);
export const getSearchQuery = createSelector([getUi], (ui) => ui.searchQuery);

export const getProductDetail = createSelector(
  [getUi],
  (ui) => ui.productDetail
);
export const getSelectedRentTime = createSelector(
  [getProductDetail],
  (ui) => ui.selectedRentTime
);
