import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import T from "prop-types";

import Navbar from "components/Layout/Navbar";

import { getHeaderData } from "./Header.helpers";

function Header({ navigationData, pageHeaderData }) {
  const t = useTranslations();
  const { asPath, query, pathname } = useRouter();

  const { header, strippedDownRoute, enrichedNavigationData, breadcrumbs } =
    getHeaderData(navigationData, pathname, asPath, t);

  return (
    <>
      <Navbar
        variant={header.navbarBG ? "bg" : null}
        navigationData={navigationData}
      />
      {Boolean(header.HeaderComponent) && (
        <header.HeaderComponent
          breadcrumbs={breadcrumbs}
          navigationData={enrichedNavigationData}
          variant={strippedDownRoute || "home"}
          currentRoute={strippedDownRoute}
          query={query?.q}
          dynamicData={pageHeaderData}
        />
      )}
    </>
  );
}

Header.propTypes = {
  navigationData: T.arrayOf(T.shape({})),
  pageHeaderData: T.shape({
    title: T.string,
    description: T.string,
  }),
};

Header.defaultProps = {
  navigationData: [],
  pageHeaderData: {},
};

export default Header;
