import { useTranslations } from "next-intl";

import { Container } from "components/MUI";
import { Body, H1 } from "components/atoms/Typography";
import theme from "config/theme";

import { HeaderBox } from "./HeaderVariants.styles";

function HighlightHeader() {
  const t = useTranslations();

  return (
    <HeaderBox component="header" paddingBottom={10}>
      <Container maxWidth="xl">
        <H1 sx={{ pt: theme.spacing(8) }}>
          {t("header.faq.h1")} –{" "}
          <Body component="span" variant="h1" sx={{ fontWeight: 300 }}>
            {t("header.faq.h1_additional")}
          </Body>
        </H1>
      </Container>
    </HeaderBox>
  );
}

export default HighlightHeader;
