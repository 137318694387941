/* eslint-disable import/prefer-default-export */

const getValueByName = (name, values) => {
  // TODO: THIS ONLY WORKS FOR NESTING IN 3 LEVEL DEEPNESS
  const parts = name.split(".");

  const initialValue = {
    [parts.length === 3]: values?.[parts[0]]?.[parts[1]]?.[parts[2]] ?? null,
    [parts.length === 2]: values?.[parts[0]]?.[parts[1]] ?? null,
    [parts.length === 1]: values?.[name] ?? null,
  }.true;

  return initialValue;
};

export { getValueByName };
