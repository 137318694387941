import axios from "axios";
import { DEFAULT_ITEMS_PER_PAGE } from "config/uiOptions";

const getProducts = (params, path = "", rest = {}) =>
  axios({
    method: "get",
    url: `${path}/api/products`,
    params: {
      ...params,
      "pagination[pageSize]": DEFAULT_ITEMS_PER_PAGE, // TODO: INTRODUCE PAGINATION
    },
    ...rest,
  })
    .then((response) => response?.data)
    .catch(() => ({
      error: "Error",
    }));

// eslint-disable-next-line import/prefer-default-export
export { getProducts };
