import { styled } from "components/MUI";

const CustomIcon = styled("span")(({ theme }) => ({
  width: 15,
  height: 15,
  borderRadius: "1px",
  backgroundColor: "#fff",
  border: "1px solid #323F53",
  ".Mui-focusVisible &": {
    outline: "2px auto #000",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    borderColor: "#858585",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
  ".Mui-checked &": {
    borderColor: theme.palette.primary.black09,
  },
}));

const CustomCheckedIcon = styled(CustomIcon)({
  backgroundColor: "#fff",
  "&:before": {
    display: "block",
    width: 13,
    height: 13,
    backgroundColor: "#000",
    clipPath:
      "polygon(20% 50.77%,26% 45.385%,41.43% 59.235%,74% 30%,80% 35.385%,41.43% 70%,20% 50.77%)",
    content: '""',
  },
});

export { CustomCheckedIcon, CustomIcon };
