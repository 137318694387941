import T from "prop-types";

import { Container } from "components/MUI";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import GenericJsonLd from "components/atoms/GenericJsonLd";

function Layout({
  children,
  navigationData,
  manufacturerData,
  pageHeaderData,
}) {
  return (
    <>
      <GenericJsonLd />
      <Header navigationData={navigationData} pageHeaderData={pageHeaderData} />
      <Container component="main" maxWidth="false" disableGutters>
        {children}
      </Container>
      <Footer
        navigationData={navigationData}
        manufacturerData={manufacturerData}
      />
    </>
  );
}

Layout.propTypes = {
  children: T.node.isRequired,
  navigationData: T.arrayOf(T.shape({})),
  manufacturerData: T.arrayOf(T.shape({})),
  pageHeaderData: T.shape({}),
};

Layout.defaultProps = {
  navigationData: [],
  manufacturerData: [],
  pageHeaderData: {},
};

export default Layout;
