// eslint-disable-next-line import/prefer-default-export
export const prepareAboSubmissionPayload = (body) => {
  const hasDifferentDeliveryAddress = body.different_delivery_address;

  return {
    ...body,
    address_delivery: hasDifferentDeliveryAddress
      ? { ...body.address_billing, ...body.address_delivery }
      : body.address_billing,
  };
};
