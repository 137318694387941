import * as Yup from "yup";

const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validation = Yup.object().shape({
  email: Yup.string().matches(email, "format_email").required("required"),
  data_privacy_accepted: Yup.bool().oneOf([true], "need_to_be_checked"),
});

// eslint-disable-next-line import/prefer-default-export
export { validation };
