import { Button, styled, MenuItem, Link as MuiLink } from "components/MUI";

const StyledLink = styled(MuiLink, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }) => ({
  fontWeight: isActive ? 700 : 400,
  fontSize: "0.875rem",
  width: "max-content",
  color: "inherit",
  cursor: "pointer",
}));

const RectangleButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  background: theme.palette.custom.darkGrey,
  border: `2px solid ${theme.palette.custom.darkGrey}`,
  color: theme.palette.primary.contrastText,
  textAlign: "center",
  height: "100%",
  width: "100%",
  "&:hover, :focus": {
    background: theme.palette.custom.darkGrey,
    borderColor: theme.palette.custom.lightGrey,
    filter: `drop-shadow(${theme.shadows[3]})`,
  },
}));

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }) => ({
  fontWeight: isActive ? 700 : 400,
  fontSize: "0.875rem",
}));

export { RectangleButton, StyledLink, StyledMenuItem };
