import { useTranslations } from "next-intl";
import T from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dynamic from "next/dynamic";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  ListItem,
  useMediaQuery,
  Button,
  Box,
} from "components/MUI";
import { StyledMenuItem } from "components/Link/Link.styles";
import theme from "config/theme";
import { isError, isLoading, isSuccess } from "store/store.helpers";
import CustomIcon from "components/atoms/CustomIcon";
import { Checkbox, Form, SubmitButton, TextField } from "components/Form";
import { CaptionBold } from "components/atoms/Typography";
import { initialValues } from "config/forms/newsletter_signup.constants";
import { validation } from "config/forms/newsletter_signup.validation";
import { reset, submitNewsletterIntent } from "store/marketing/marketing.slice";
import { getNewsletter } from "store/marketing/marketing.selectors";
import { useRouter } from "next/router";

const Markdown = dynamic(() => import("components/atoms/Markdown"), {
  ssr: false,
});

function NewsletterDialog({ mobile }) {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  const { loadingState } = useSelector(getNewsletter);
  const dispatch = useDispatch();
  const { locale } = useRouter();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (isError(loadingState.status)) {
      dispatch(reset("newsletter"));
    }
  };

  const handleSubmit = (values) => {
    dispatch(submitNewsletterIntent({ ...values, locale }));
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        PaperProps={{ elevation: 0 }}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle textAlign="center" typography="h2">
          {isSuccess(loadingState.status)
            ? t("newsletter_signup.success_title")
            : t("newsletter_signup.title")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
              color: theme.palette.primary,
            }}
          >
            <CustomIcon fontSize="1rem" name="cross" />
          </IconButton>
        </DialogTitle>
        <DialogContent align="center">
          {isSuccess(loadingState.status) ? (
            <>
              <DialogContentText sx={{ mb: theme.spacing(2) }}>
                {t("newsletter_signup.success_text")}
                <br />
                <CustomIcon
                  sx={{ my: theme.spacing(3), fontSize: "8rem" }}
                  name="checkmarkEdges"
                  fill={theme.palette.success.main}
                />
              </DialogContentText>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleClose}
              >
                {t("newsletter_signup.success_button_label")}
              </Button>
            </>
          ) : (
            <>
              <DialogContentText sx={{ mb: theme.spacing(2) }}>
                {t("newsletter_signup.text")}
              </DialogContentText>
              <Form
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                id={t("newsletter_signup.form_id")}
              >
                <TextField
                  name="email"
                  label={t("newsletter_signup.email.label")}
                  placeholder={t("newsletter_signup.email.placeholder")}
                  validateOnChange
                  margin="normal"
                />
                <Checkbox
                  name="data_privacy_accepted"
                  margin="normal"
                  label={
                    <Markdown
                      text={t("newsletter_signup.data_privacy_message", {
                        data_privacy_url: t("links.data_privacy.link"),
                      })}
                    />
                  }
                  validateOnChange
                />
                <Box sx={{ mt: theme.spacing(3) }}>
                  {isError(loadingState.status) && (
                    <CaptionBold
                      component="p"
                      align="center"
                      sx={{ mb: theme.spacing(1.5) }}
                      color={theme.palette.error.main}
                    >
                      {t(`form.validation.${loadingState.error}`)}
                    </CaptionBold>
                  )}
                  <SubmitButton isLoading={isLoading(loadingState.status)}>
                    {t("newsletter_signup.submit_button_label")}
                  </SubmitButton>
                </Box>
              </Form>
            </>
          )}
        </DialogContent>
      </Dialog>
      {mobile ? (
        <StyledMenuItem key="newsletter_signup" onClick={handleClick}>
          {t(`links.newsletter_signup.title`)}
        </StyledMenuItem>
      ) : (
        <ListItem sx={{ width: "max-content" }} key="newsletter_signup">
          <Button variant="outlined" size="small" onClick={handleClick}>
            {t(`links.newsletter_signup.title`)}
          </Button>
        </ListItem>
      )}
    </>
  );
}

NewsletterDialog.propTypes = {
  mobile: T.bool,
};

NewsletterDialog.defaultProps = {
  mobile: false,
};

export default NewsletterDialog;
