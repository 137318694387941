import T from "prop-types";
import { useFormikContext } from "formik";
import { useTranslations } from "next-intl";
import { useState } from "react";

import {
  FormControl,
  InputLabel,
  InputBase,
  FormHelperText,
} from "components/MUI";
import { getValueByName } from "./helpers";

function TextField({
  name,
  label,
  showErrorText,
  margin,
  validateOnChange,
  placeholder,
  required,
  type,
}) {
  const t = useTranslations("form.validation");
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext();

  const [ownState, setOwnState] = useState(getValueByName(name, values));

  const handleChange = (e) => {
    let result = e.target.value;
    if (type === "number" && !Number.isNaN(e.target.valueAsNumber)) {
      result = e.target.valueAsNumber;
    }

    setOwnState(result);
    if (validateOnChange) {
      setFieldValue(name, result);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name);
    if (!validateOnChange) {
      setFieldValue(name, ownState);
    }
  };

  const hasError = Boolean(
    getValueByName(name, touched) && getValueByName(name, errors)
  );

  return (
    <FormControl
      variant="standard"
      margin={margin}
      fullWidth
      error={hasError}
      required={required}
    >
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <InputBase
        name={name}
        placeholder={placeholder}
        value={ownState}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
      />
      {hasError && showErrorText && (
        <FormHelperText>{t(`${getValueByName(name, errors)}`)}</FormHelperText>
      )}
    </FormControl>
  );
}

TextField.propTypes = {
  name: T.string.isRequired,
  placeholder: T.string,
  validateOnChange: T.bool,
  showErrorText: T.bool,
  required: T.bool,
  label: T.string,
  type: T.string,
  margin: T.string,
};

TextField.defaultProps = {
  placeholder: null,
  required: false,
  label: null,
  validateOnChange: false,
  showErrorText: true,
  type: "text",
  margin: "dense",
};

export default TextField;
