import { createSelector } from "@reduxjs/toolkit";
import { getMarketing } from "store/store.selectors";

export const getNewsletter = createSelector(
  [getMarketing],
  (marketing) => marketing.newsletter
);

export const getPriceComparison = createSelector(
  [getMarketing],
  (marketing) => marketing.priceComparison
);
