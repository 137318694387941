import T from "prop-types";

import { Container } from "components/MUI";
import Breadcrumbs from "components/molecules/Breadcrumbs";
import theme from "config/theme";

import { HeaderBox } from "./HeaderVariants.styles";
import SearchTextField from "./SearchTextField";
import SubNavigation from "./SubNavigation";

function CategorySearchHeader({
  breadcrumbs,
  navigationData,
  currentRoute,
  query,
}) {
  return (
    <HeaderBox component="header">
      <Container maxWidth="xl">
        <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ py: theme.spacing(3) }} />
        <SearchTextField searchValue={query?.q} />
        <SubNavigation
          navigationData={navigationData}
          activeRoute={currentRoute}
        />
      </Container>
    </HeaderBox>
  );
}

CategorySearchHeader.propTypes = {
  breadcrumbs: T.arrayOf(
    T.shape({ key: T.string, href: T.string, title: T.string })
  ),
  navigationData: T.arrayOf(T.shape({})),
  currentRoute: T.string.isRequired,
  query: T.string,
};

CategorySearchHeader.defaultProps = {
  navigationData: [],
  breadcrumbs: [],
  query: "",
};

export default CategorySearchHeader;
