import { MARKETING_TYPES } from "config/system";

const initialValues = {
  type: MARKETING_TYPES.NEWSLETTER,
  data_privacy_accepted: false,
  active: true,
  email: "",
};

// eslint-disable-next-line import/prefer-default-export
export { initialValues };
