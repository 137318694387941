/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAboRequest } from "api/aborequest";
import { getStrapiAttributes } from "helpers/utils";
import { LOADING_STATE } from "store/store.constants";
import { prepareAboSubmissionPayload } from "./rentingCart.helpers";

const initialState = {
  products: [],
  aboRequest: {
    data: {},
    loadingState: {
      status: LOADING_STATE.INITIAL,
      error: null,
    },
  },
};

export const submitAboRequest = createAsyncThunk(
  "rentingCard/submitAboRequest",
  async (body) => {
    const response = await createAboRequest(prepareAboSubmissionPayload(body));

    return { ...getStrapiAttributes(response.data), ...body };
  }
);

export const rentingCart = createSlice({
  name: "rentingCart",
  initialState,
  reducers: {
    setProduct: (state, { payload }) => {
      state.products = [payload];
    },
    resetProducts: (state) => {
      state.products = initialState.products;
    },
    reset: (state) => {
      state.products = initialState.products;
      state.aboRequest = initialState.aboRequest;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitAboRequest.pending, (state) => {
        state.aboRequest.loadingState = {
          status: LOADING_STATE.LOADING,
          error: null,
        };
      })
      .addCase(submitAboRequest.rejected, (state, action) => {
        state.aboRequest.loadingState = {
          status: LOADING_STATE.ERROR,
          error: action?.error?.message || "error_submit_abo",
        };
      })
      .addCase(submitAboRequest.fulfilled, (state, action) => {
        state.products = initialState.products;
        state.aboRequest.data = action.payload;
        state.aboRequest.loadingState = {
          status: LOADING_STATE.SUCCESS,
        };
      });
  },
});

export const { setProduct, reset, resetProducts } = rentingCart.actions;

export default rentingCart.reducer;
