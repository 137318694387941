/* eslint-disable import/prefer-default-export */
import { headerTypes } from "./Header.config";

const getHeaderType = (navigationSlugs, path) => {
  const headerFound = Object.values(headerTypes).find((item) =>
    item.relatedPaths.includes(path)
  );

  if (headerFound) {
    return {
      navbarBG: headerFound.navbarBG,
      HeaderComponent: headerFound.HeaderComponent,
    };
  }

  if (navigationSlugs.includes(path)) {
    return headerTypes.categorySearchHeader;
  }

  if (path === "[blog-post]") {
    return headerTypes.blogHeader;
  }

  return { navbarBG: false };
};

const getRoute = (pathname, asPath) => {
  const baseRoute = pathname.includes("[product_group]") ? asPath : pathname;

  // Split path on "/"
  const splittedRoute = baseRoute.split(/[/]/);

  // Take last route and remove params
  const strippedDownRoute =
    splittedRoute[splittedRoute.length - 1].split(/[#|?]/)[0];

  return strippedDownRoute;
};

export const getHeaderData = (navigationData, pathname, asPath, t) => {
  const strippedDownRoute = getRoute(pathname, asPath);
  const enrichedNavigationData = [
    ...navigationData.map((item) => ({
      ...item,
      href: `/${item.slug}`,
    })),
    {
      href: t("links.all_products.link"),
      slug: t("links.all_products.slug"),
      name: t("links.all_products.title"),
    },
  ];

  const found = [
    ...enrichedNavigationData,
    {
      href: t("links.search.link"),
      slug: t("links.search.slug"),
      name: t("links.search.title"),
    },
  ].find((item) => item.slug === strippedDownRoute);

  return {
    header: getHeaderType(
      navigationData.map((item) => item.slug),
      strippedDownRoute
    ),
    strippedDownRoute,
    enrichedNavigationData,
    breadcrumbs: [
      {
        key: "product_overview",
        href: t("links.product_overview.slug"),
        title: t("links.product_overview.title"),
      },
      Boolean(found) && {
        key: found.slug,
        href: found.slug,
        title: found.name,
      },
    ],
  };
};
