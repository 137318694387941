import T from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import SearchIcon from "@mui/icons-material/Search";

import { InputAdornment } from "components/MUI";
import Link from "components/Link";

import { SearchField } from "./HeaderVariants.styles";

const regex = /^[a-zA-Z0-9_ \-äÄöÖüÜß]+$/;

function SearchTextField({ searchValue }) {
  const t = useTranslations();
  const ref = useRef();
  const router = useRouter();
  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  const handleChange = (e) => {
    if (e.target.value) {
      if (!regex.test(e.target.value)) {
        e.preventDefault();
        return null;
      }
      setValue(e.target.value);
    } else {
      setValue("");
    }
    return null;
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      router.push({ pathname: "suche", query: { q: value } });
    }
  };

  return (
    <SearchField
      id="search"
      ref={ref}
      aria-label={t("header.search.search_aria_label")}
      fullWidth
      hiddenLabel
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        disableUnderline: true,
        value: value || "",
        placeholder: t("header.search.search_placeholder"),
        startAdornment: (
          <InputAdornment position="start">
            <Link
              type="icon_button"
              aria-label={t("header.search.search_icon_btn_aria_label")}
              href={{ pathname: "suche", query: { q: value } }}
            >
              <SearchIcon color="primary" sx={{ transform: " scaleX(-1)" }} />
            </Link>
          </InputAdornment>
        ),
      }}
      variant="filled"
    />
  );
}

SearchTextField.propTypes = {
  searchValue: T.string,
};

SearchTextField.defaultProps = {
  searchValue: "",
};

export default SearchTextField;
