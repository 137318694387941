/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createFeedback } from "api/feedback";
import { LOADING_STATE } from "store/store.constants";

const initialState = {
  loadingState: {
    status: LOADING_STATE.INITIAL,
    error: null,
  },
};

export const submitFeedback = createAsyncThunk(
  "feedback/submitFeedback",
  async (body) => {
    await createFeedback(body);

    return null;
  }
);

export const feedback = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    reset: (state) => {
      state.loadingState = initialState.loadingState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitFeedback.pending, (state) => {
        state.loadingState = {
          status: LOADING_STATE.LOADING,
          error: null,
        };
      })
      .addCase(submitFeedback.rejected, (state, action) => {
        state.loadingState = {
          status: LOADING_STATE.ERROR,
          error: action?.error?.message || "error_submit_feedback",
        };
      })
      .addCase(submitFeedback.fulfilled, (state) => {
        state.loadingState = {
          status: LOADING_STATE.SUCCESS,
        };
      });
  },
});

export const { reset } = feedback.actions;

export default feedback.reducer;
