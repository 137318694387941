import Image from "next/image";
import { Box, Grid, styled, Container } from "components/MUI";

const TeaserCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ theme, mobile }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  border: `0.9px solid ${theme.palette.custom.lightGrey}`,
  height: "100%",
  transition: "all .2s ease-in-out",
  position: "relative",
  "&:hover": {
    transform: "scale(1.05)",
    transformOrigin: "top",
    border: `0.9px solid ${theme.palette.custom.darkGrey}`,
    ".hide": {
      display: "block",
    },
  },
  ...(mobile === true && {
    paddingBottom: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
  }),
}));

const TeaserCardImage = styled(Image)(() => ({
  objectFit: "contain",
  objectPosition: "top",
}));

const TeaserCardImageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "177px",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    height: "120px",
  },
  marginBottom: theme.spacing(1.5),
}));

const TeaserBoxBackground = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "538px",
  zIndex: "-1",
  width: "120%",
  background:
    "linear-gradient(121.72deg, rgba(234, 238, 242, 0.3) 13.46%, rgba(208, 215, 227, 0.3) 73.35%)",
  transform: "translate3d(-30%, -20%, 0)",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const TeaserBoxLine = styled(Box)(({ theme }) => ({
  backgroundImage: "url(/images/line.svg)",
  backgroundSize: "cover",
  height: "14px",
  zIndex: -1,
  position: "absolute",
  width: "108%",
  marginTop: "27.4%",
  marginLeft: "-5%",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const TeaserBoxWithImage = styled(Box)(({ theme }) => ({
  "&:before": {
    content: "''",
    zIndex: "-1",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(121.72deg, rgba(234, 238, 242, 0.3) 13.46%, rgba(208, 215, 227, 0.3) 73.35%)",
    transform: "translate3d(-30%, 15%, 0)",
    [theme.breakpoints.down("lg")]: {
      transform: "translate3d(-30%, 10%, 0) scale(1, 0.8)",
    },
    [theme.breakpoints.down("md")]: {
      transform: "translate3d(-15%, 10%, 0)",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "translate3d(-15%, 15%, 0)",
    },
  },
  position: "relative",
  minHeight: "424px",
  [theme.breakpoints.down("md")]: {
    minHeight: "400px",
    width: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "200px",
    width: "80vw",
  },
}));

const TeaserBoxImage = styled(Image)(({ theme }) => ({
  objectFit: "contain",
  objectPosition: "right",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
}));

const TeaserImageGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const TeaserContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  minHeight: "485px",
}));

export {
  TeaserBoxWithImage,
  TeaserBoxBackground,
  TeaserBoxImage,
  TeaserImageGrid,
  TeaserContainer,
  TeaserCard,
  TeaserBoxLine,
  TeaserCardImageWrapper,
  TeaserCardImage,
};
