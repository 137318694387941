import axios from "axios";

const createFeedback = (body) =>
  axios({
    method: "post",
    url: "/api/feedbacks",
    data: { data: body },
  })
    .then((response) => response?.data)
    .catch((err) => {
      if (err.response?.status === 409) {
        throw new Error(err.response?.data?.message);
      } else {
        throw new Error("error_submit_feedback");
      }
    });

// eslint-disable-next-line import/prefer-default-export
export { createFeedback };
