import getConfig from "next/config";

/* eslint-disable prefer-destructuring */

// Environment Variables
const PUBLIC_URL = process.env.NEXT_PUBLIC_APP_URL || "http://localhost:3000";
const PUBLIC_ASSET_URL = process.env.NEXT_PUBLIC_ASSET_URL || "";
const LOCALES = process.env.NEXT_PUBLIC_LOCALES?.split(",") || [
  "de",
  "en",
  "it",
];
const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE || "en";
const ENV_IS_DEVELOPMENT = process.env.NEXT_PUBLIC_IS_PRODUCTION !== "true";
const DEBUG_CACHING = process.env.NEXT_PUBLIC_IS_PRODUCTION !== "true";
const LOCALE_DOMAINS = {
  en: process.env.NEXT_PUBLIC_LOCALE_DOMAIN_EN || "/",
  de: process.env.NEXT_PUBLIC_LOCALE_DOMAIN_DE || "/",
  it: process.env.NEXT_PUBLIC_LOCALE_DOMAIN_IT || "/",
};

// SECRET Variables
const {
  serverRuntimeConfig = {
    CMSApiToken: "test",
    CMSApiUrl: "test",
  },
} = getConfig() || {};
const CMS_API_TOKEN = serverRuntimeConfig.CMSApiToken;
const CMS_API_URL = serverRuntimeConfig.CMSApiUrl;
const GTM_ID = "GTM-ND3D48D";

// FEEDBACK TYPES
const FEEDBACK_TYPES = {
  LEAVING_CHECKOUT: "checkout_leaving",
};

// MARKETING TYPES
const MARKETING_TYPES = {
  NEWSLETTER: "newsletter",
  PRICE_COMPARISON: "price_comparison",
};

export {
  PUBLIC_URL,
  LOCALES,
  DEFAULT_LOCALE,
  PUBLIC_ASSET_URL,
  ENV_IS_DEVELOPMENT,
  DEBUG_CACHING,
  CMS_API_TOKEN,
  CMS_API_URL,
  FEEDBACK_TYPES,
  MARKETING_TYPES,
  GTM_ID,
  LOCALE_DOMAINS,
};
