import NextLink from "next/link";
import T from "prop-types";
import {
  IconButton,
  Button as MuiButton,
  CardActionArea,
} from "components/MUI";

import { RectangleButton, StyledLink, StyledMenuItem } from "./Link.styles";

export default function Link({ href, type, variant, children, ...props }) {
  if (type === "button") {
    return (
      <NextLink href={href} passHref legacyBehavior>
        <MuiButton variant={variant} component="a" {...props}>
          {children}
        </MuiButton>
      </NextLink>
    );
  }

  if (type === "cardaction") {
    return (
      <NextLink href={href} passHref legacyBehavior>
        <CardActionArea {...props}>{children}</CardActionArea>
      </NextLink>
    );
  }

  if (type === "rectangle_button") {
    return (
      <NextLink href={href} passHref legacyBehavior>
        <RectangleButton component="a" {...props}>
          {children}
        </RectangleButton>
      </NextLink>
    );
  }

  if (type === "menu_item") {
    return (
      <NextLink href={href} passHref legacyBehavior>
        <StyledMenuItem component="a" {...props}>
          {children}
        </StyledMenuItem>
      </NextLink>
    );
  }

  if (type === "icon_button") {
    return (
      <NextLink href={href} passHref legacyBehavior>
        <IconButton component="a" type="button" {...props}>
          {children}
        </IconButton>
      </NextLink>
    );
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      <StyledLink {...props}>{children}</StyledLink>
    </NextLink>
  );
}

Link.propTypes = {
  type: T.oneOf([
    "button",
    "a",
    "cardaction",
    "rectangle_button",
    "menu_item",
    "icon_button",
  ]),
  variant: T.string,
  children: T.node.isRequired,
  href: T.oneOfType([T.string, T.shape({})]).isRequired,
};

Link.defaultProps = {
  type: "a",
  variant: "outlined",
};
