import T from "prop-types";
import { useState } from "react";
import { useFormikContext } from "formik";
import { useTranslations } from "next-intl";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
} from "components/MUI";
import CustomIcon from "components/atoms/CustomIcon";

import { getValueByName } from "./helpers";

function Select({
  name,
  validateOnChange,
  label,
  options,
  showErrorText,
  required,
  margin,
}) {
  const t = useTranslations("form.validation");
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext();

  const [ownState, setOwnState] = useState(getValueByName(name, values));

  const handleChange = (e) => {
    setOwnState(e.target.value);
    if (validateOnChange) {
      setFieldValue(name, e.target.value);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name);
    if (!validateOnChange) {
      setFieldValue(name, ownState);
    }
  };

  const hasError = Boolean(
    getValueByName(name, touched) && getValueByName(name, errors)
  );

  const renderIcon = (props) => <CustomIcon name="chevronDown" {...props} />;

  return (
    <FormControl
      variant="standard"
      margin={margin}
      error={hasError}
      fullWidth
      required={required}
    >
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <NativeSelect
        id={name}
        disableUnderline
        value={ownState}
        onChange={handleChange}
        onBlur={handleBlur}
        IconComponent={renderIcon}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
      {hasError && showErrorText && (
        <FormHelperText>{t(`${getValueByName(name, errors)}`)}</FormHelperText>
      )}
    </FormControl>
  );
}

Select.propTypes = {
  name: T.string.isRequired,
  options: T.arrayOf(
    T.shape({ value: T.oneOfType([T.string, T.number]), label: T.string })
  ).isRequired,
  validateOnChange: T.bool,
  label: T.string.isRequired,
  showErrorText: T.bool,
  required: T.bool,
  margin: T.string,
};

Select.defaultProps = {
  showErrorText: true,
  validateOnChange: false,
  required: false,
  margin: "dense",
};

export default Select;
