const NAVIGATION_LINKS = [
  [
    {
      title: "product_overview",
      static: true,
    },
    {
      title: "all_products",
      static: true,
    },
    {
      title: "faq",
      static: true,
    },
    {
      title: "about_us",
      static: true,
    },
    {
      title: "blog",
      static: true,
    },
  ],
  [
    {
      title: "data_privacy",
      static: true,
    },
    {
      title: "legal",
      static: true,
    },
  ],
];

const composeNavigation = (navigationData, manufacturerData) => {
  const fC = Math.round(navigationData.length / 2);

  const dynamicNavigation =
    navigationData.length > 0
      ? [
          [...Array(fC).keys()].map((i) => navigationData[i]),
          [...Array(navigationData.length - fC).keys()].map(
            (i) => navigationData[fC + i]
          ),
        ]
      : [];

  const dynamicAGBNavigation = manufacturerData.map((m) => ({
    title: "terms_and_conditions",
    ...m,
    agb: true,
  }));

  return [
    NAVIGATION_LINKS[0],
    ...dynamicNavigation,
    [...dynamicAGBNavigation, ...NAVIGATION_LINKS[1]],
  ];
};

// eslint-disable-next-line import/prefer-default-export
export { composeNavigation };
