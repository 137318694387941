/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProductsBySettings } from "store/products/products.slice";

const initialState = {
  filter: {
    manufacturers: [],
    productGroup: [],
  },
  sortBy: null,
  searchQuery: "",
  productDetail: {
    selectedRentTime: null,
  },
};

export const setFilterByManufacturer = createAsyncThunk(
  "ui/setFilterByManufacturer",
  async (manufacturers, { dispatch }) => {
    dispatch(getProductsBySettings({ manufacturers }));
    return manufacturers;
  }
);

export const setSortBy = createAsyncThunk(
  "ui/setSortBy",
  async (sortOption, { dispatch }) => {
    dispatch(getProductsBySettings({ sortOption }));
    return sortOption;
  }
);

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setProductGroupFilter: (state, { payload }) => {
      state.filter.productGroup = [payload.id];
      // resetting filter options, e.g bc of hitting product group page
      if (payload.withReset) {
        state.filter.manufacturers = [];
        state.sortBy = initialState.sortBy;
        state.searchQuery = initialState.searchQuery;
        state.productDetail = initialState.productDetail;
      }
    },
    setSearchQuery: (state, { payload }) => {
      // Reset filter params on new search
      state.sortBy = initialState.sortBy;
      state.filter = initialState.filter;
      state.productDetail = initialState.productDetail;
      state.searchQuery = payload;
    },
    setSelectedRentTime: (state, { payload }) => {
      state.productDetail.selectedRentTime = payload;
    },
    resetUi: (state) => {
      state.sortBy = initialState.sortBy;
      state.filter = initialState.filter;
      state.searchQuery = initialState.searchQuery;
      state.productDetail = initialState.productDetail;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setFilterByManufacturer.fulfilled, (state, action) => {
        state.filter.manufacturers = action.payload;
      })
      .addCase(setSortBy.fulfilled, (state, action) => {
        state.sortBy = action.payload;
      });
  },
});

export const {
  setSearchQuery,
  setSelectedRentTime,
  resetUi,
  setProductGroupFilter,
} = uiSlice.actions;

export default uiSlice.reducer;
