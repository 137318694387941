/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewsletterIntent,
  createPriceComparisonDownloadIntent,
} from "api/marketing";
import { LOADING_STATE } from "store/store.constants";

const initialState = {
  newsletter: {
    data: null,
    loadingState: {
      status: LOADING_STATE.INITIAL,
      error: null,
    },
  },
  priceComparison: {
    data: null,
    loadingState: {
      status: LOADING_STATE.INITIAL,
      error: null,
    },
  },
};

export const submitNewsletterIntent = createAsyncThunk(
  "marketing/submitNewsletterIntent",
  async (body) => {
    await createNewsletterIntent(body);

    return null;
  }
);

export const submitPriceComparisonDownloadIntent = createAsyncThunk(
  "marketing/submitPriceComparisonDownloadIntent",
  async (body) => {
    const response = await createPriceComparisonDownloadIntent(body);

    return response;
  }
);

export const marketing = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    reset: (state, { payload }) => {
      state[payload].loadingState = initialState[payload].loadingState;
      state[payload].data = initialState[payload].data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitNewsletterIntent.pending, (state) => {
        state.newsletter.loadingState = {
          status: LOADING_STATE.LOADING,
          error: null,
        };
      })
      .addCase(submitNewsletterIntent.rejected, (state, action) => {
        state.newsletter.loadingState = {
          status: LOADING_STATE.ERROR,
          error: action?.error?.message || "error_submit_newsletter_intent",
        };
      })
      .addCase(submitNewsletterIntent.fulfilled, (state) => {
        state.newsletter.loadingState = {
          status: LOADING_STATE.SUCCESS,
        };
      })
      .addCase(submitPriceComparisonDownloadIntent.pending, (state) => {
        state.priceComparison.loadingState = {
          status: LOADING_STATE.LOADING,
          error: null,
        };
      })
      .addCase(
        submitPriceComparisonDownloadIntent.rejected,
        (state, action) => {
          state.priceComparison.loadingState = {
            status: LOADING_STATE.ERROR,
            error:
              action?.error?.message ||
              "error_submit_price_comparison_download_intent",
          };
        }
      )
      .addCase(
        submitPriceComparisonDownloadIntent.fulfilled,
        (state, action) => {
          const sheet = action?.payload?.url;

          if (sheet) {
            state.priceComparison.data = action.payload;
            state.priceComparison.loadingState = {
              status: LOADING_STATE.SUCCESS,
            };
          } else {
            state.priceComparison.loadingState = {
              status: LOADING_STATE.ERROR,
              error: "error_submit_price_comparison_download_intent",
            };
          }
        }
      );
  },
});

export const { reset } = marketing.actions;

export default marketing.reducer;
