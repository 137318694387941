import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import uiReducer from "./ui/ui.slice";
import productsReducer from "./products/products.slice";
import rentingCartReducer from "./rentingCart/rentingCart.slice";
import feedbackReducer from "./feedback/feedback.slice";
import marketingReducer from "./marketing/marketing.slice";

const persistReningCartConfig = {
  key: "rentingCart",
  version: 1,
  storage,
  whitelist: ["products"],
};

const persistedRentingCartReducer = persistReducer(
  persistReningCartConfig,
  rentingCartReducer
);

export function makeStore() {
  return configureStore({
    reducer: {
      ui: uiReducer,
      products: productsReducer,
      rentingCart: persistedRentingCartReducer,
      feedback: feedbackReducer,
      marketing: marketingReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
}

const store = makeStore();
export const persistor = persistStore(store);

export default store;
