import { useState } from "react";
import { useTranslations } from "next-intl";
import T from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Box, List, ListItem, Menu, useMediaQuery } from "components/MUI";
import Link from "components/Link";

import { NavigationBox, StyledMenuButton } from "./HeaderVariants.styles";

const getSplittedNavigation = (navigationData, maxAmount) => {
  if (navigationData.length > maxAmount) {
    return {
      main: navigationData.slice(0, maxAmount - 1),
      sub: navigationData.slice(maxAmount - 1, navigationData.length),
    };
  }

  return { main: navigationData, sub: null };
};

function SubNavigation({ navigationData, activeRoute }) {
  const t = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);

  const subNavigationItemAmount = {
    [true]: 2,
    [useMediaQuery("(min-width:420px)")]: 3,
    [useMediaQuery("(min-width:520px)")]: 4,
    [useMediaQuery("(min-width:780px)")]: 6,
    [useMediaQuery("(min-width:1050px)")]: 8,
  }.true;

  const splittedNavigationData = getSplittedNavigation(
    navigationData,
    subNavigationItemAmount
  );

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <NavigationBox>
      <Box component={List}>
        {splittedNavigationData.main.map((item) => (
          <ListItem key={item.slug}>
            <Link
              underline="hover"
              href={item.href}
              isActive={activeRoute === item.slug}
            >
              {item.name}
            </Link>
          </ListItem>
        ))}
        {splittedNavigationData.sub && (
          <>
            <StyledMenuButton
              id="productgroup-button"
              size="medium"
              aria-controls={open ? "productgroup-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="text"
              disableElevation
              onClick={handleClick}
              endIcon={
                open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {t(`links.more`)}
            </StyledMenuButton>
            <Menu
              id="productgroup-menu"
              MenuListProps={{
                "aria-labelledby": "productgroup-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              elevation={1}
              open={open}
              onClose={handleClose}
            >
              {[
                ...splittedNavigationData.sub.map((subLink) => [
                  <Link
                    type="menu_item"
                    href={`/${subLink.slug}`}
                    key={subLink.slug}
                    onClick={handleClose}
                    disableRipple
                    underline="none"
                    display="flex"
                    isActive={activeRoute === subLink.slug}
                  >
                    {subLink.name}
                  </Link>,
                ]),
              ]}
            </Menu>
          </>
        )}
      </Box>
    </NavigationBox>
  );
}

SubNavigation.propTypes = {
  navigationData: T.arrayOf(T.shape({})),
  activeRoute: T.string,
};

SubNavigation.defaultProps = {
  navigationData: [],
  activeRoute: "",
};

export default SubNavigation;
