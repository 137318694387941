import T from "prop-types";
import { useFormikContext } from "formik";
import { CircularProgress, useMediaQuery } from "@mui/material";

import { Button } from "components/MUI";
import theme from "config/theme";

function SubmitButton({ children, isLoading, ...props }) {
  const { handleSubmit, isValid } = useFormikContext();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Button
      onClick={handleSubmit}
      disabled={!isValid || isLoading}
      type="submit"
      color="primary"
      fullWidth
      variant="contained"
      size={isSmall ? "medium" : "large"}
      {...props}
    >
      <>
        {children}{" "}
        {isLoading && (
          <CircularProgress
            size={20}
            color="inherit"
            sx={{ ml: theme.spacing(2) }}
          />
        )}
      </>
    </Button>
  );
}

SubmitButton.propTypes = {
  children: T.node.isRequired,
  isLoading: T.bool,
};

SubmitButton.defaultProps = {
  isLoading: false,
};

export default SubmitButton;
