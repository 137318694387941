import T from "prop-types";

import { TeaserContainer } from "./Teaser.styles";
import HomeTeaser from "./HomeTeaser";
import AboutUsTeaser from "./AboutUsTeaser";

function Teaser({ variant }) {
  const data = {
    home: <HomeTeaser />,
    "ueber-uns": <AboutUsTeaser />,
  };

  const content = data[variant];

  return (
    <TeaserContainer component="header" maxWidth="xl">
      {content}
    </TeaserContainer>
  );
}

Teaser.propTypes = {
  variant: T.oneOf(["home", "ueber-uns"]),
};

Teaser.defaultProps = {
  variant: "home",
};

export default Teaser;
