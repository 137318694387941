import { useTranslations } from "next-intl";
import dynamic from "next/dynamic";

import { Container } from "components/MUI";
import { Body, BodyBold, H1, H2 } from "components/atoms/Typography";
import CustomIcon from "components/atoms/CustomIcon";
import theme from "config/theme";

import {
  ErrorHeader as ErrorHeaderWrapper,
  ErrorParagraphWrapper,
  HeaderBox,
} from "./HeaderVariants.styles";
import SearchTextField from "./SearchTextField";

const Markdown = dynamic(() => import("components/atoms/Markdown"), {
  ssr: false,
});

function ErrorHeader() {
  const t = useTranslations();

  return (
    <HeaderBox component="header" paddingBottom={10}>
      <Container maxWidth="xl">
        <ErrorHeaderWrapper>
          <div>
            <Body variant="h4" color={theme.palette.primary.main}>
              {t("header.error.error")}
            </Body>
            <H2
              color={theme.palette.primary.main}
              sx={{ fontWeight: 900, fontSize: 80, my: theme.spacing(2) }}
            >
              {t("header.error.code")}
            </H2>
          </div>
          <H1>{t("header.error.h1")}</H1>
          <CustomIcon
            name="searchIconCross"
            sx={{ fontSize: "6rem", marginRight: "3rem" }}
          />
        </ErrorHeaderWrapper>
        <ErrorParagraphWrapper>
          <BodyBold component="p" sx={{ mb: theme.spacing(2) }}>
            {t("header.error.text_1")}
          </BodyBold>
          <Markdown
            text={t("header.error.text_2", {
              url: t("links.startpage.link"),
            })}
          />
        </ErrorParagraphWrapper>
        <SearchTextField />
      </Container>
    </HeaderBox>
  );
}

export default ErrorHeader;
