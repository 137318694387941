import { useRouter } from "next/router";
import { OrganizationJsonLd } from "next-seo";
import Script from "next/script";
import { useTranslations } from "next-intl";

import { PUBLIC_URL } from "config/system";

function GenericJsonLd() {
  const { pathname, asPath } = useRouter();
  const t = useTranslations();

  const baseRoute = pathname.includes("[product_group]") ? asPath : pathname;
  const isHomePage = baseRoute === "/";

  return isHomePage ? (
    <>
      {/* JSON-LD WebSite */}
      <Script
        id="jsonld-website"
        type="application/ld+json"
        strategy="lazyOnload"
      >
        {`{"@context": "https://schema.org","@type":"WebSite","url":"${PUBLIC_URL}","potentialAction":{"@type": "SearchAction","target": {"@type": "EntryPoint","urlTemplate": "${PUBLIC_URL}${t(
          "links.search.link"
        )}?q={search_term_string}"},"query-input": "required name=search_term_string"}}`}
      </Script>
    </>
  ) : (
    <>
      {/* JSON-LD Organisation */}
      <OrganizationJsonLd
        type="Organization"
        name="LaboRent"
        logo={`${PUBLIC_URL}/apple-touch-icon.png`}
        url={PUBLIC_URL}
        address={{
          streetAddress: "Leightonstr. 2",
          addressLocality: "Würzburg",
          postalCode: "97074",
          addressCountry: "Deutschland",
        }}
        contactPoint={[
          {
            telephone: t("jsonld.organisation.phone"),
            contactType: "customer service",
            email: t("jsonld.organisation.email"),
            availableLanguage: [t("jsonld.organisation.availableLanguage1")],
            areaServed: [t("jsonld.organisation.areaServed1")],
          },
        ]}
        sameAs={["https://www.linkedin.com/company/laborent-de/"]}
      />
    </>
  );
}

export default GenericJsonLd;
