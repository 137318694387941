/* eslint-disable import/prefer-default-export */
import CategorySearchHeader from "./components/CategorySearchHeader";
import ErrorHeader from "./components/ErrorHeader";
import HighlightHeader from "./components/HighlightHeader";
import SearchHeader from "./components/SearchHeader";
import BlogHeader from "./components/BlogHeader";
import Teaser from "./components/Teaser";

export const headerTypes = {
  categorySearchHeader: {
    relatedPaths: ["alle-laborgeraete", "suche"],
    navbarBG: true,
    HeaderComponent: CategorySearchHeader,
  },
  searchHeader: {
    relatedPaths: ["geraeteangebot"],
    navbarBG: true,
    HeaderComponent: SearchHeader,
  },
  errorHeader: {
    relatedPaths: ["404", "500"],
    navbarBG: true,
    HeaderComponent: ErrorHeader,
  },
  teaserHeader: {
    relatedPaths: ["", "ueber-uns"],
    navbarBG: false,
    HeaderComponent: Teaser,
  },
  highligtHeader: {
    relatedPaths: ["faq"],
    navbarBG: true,
    HeaderComponent: HighlightHeader,
  },
  blogHeader: {
    relatedPaths: ["blog"],
    navbarBG: true,
    HeaderComponent: BlogHeader,
  },
};
