import { useEffect, useState } from "react";

import { useFormatter, useTranslations } from "next-intl";
import { getImageData, getStrapiAttributes } from "helpers/utils";

const calculateProductPrice = (modifier, basePrice) =>
  basePrice.value * (modifier.price_modifier / 100) +
  basePrice.costs_maintenance_per_year / 12 +
  basePrice.costs_repair_per_year / 12 +
  (2 * basePrice.costs_shipping_de) / modifier.timeframe +
  basePrice.costs_preparation / modifier.timeframe;

const generateShortViewProductData = (products) =>
  products
    .filter((item) => item.price_modifiers?.data?.length !== 0)
    .map((p) => {
      const product = getStrapiAttributes(p);
      return {
        id: p.id,
        manufacturer: {
          name: getStrapiAttributes(product.manufacturer.data).name,
          id: product.manufacturer.data.id,
        },
        manufacturerId: product.manufacturer.data.id,
        shortDescription: product.short_description,
        image: getImageData(
          product?.images?.data?.length
            ? getStrapiAttributes(product?.images?.data[0])
            : null
        ),
        breadcrumbs: [getStrapiAttributes(product.product_group.data)],
        name: product.name,
        slug: product.slug,
        price: Math.round(product.lowest_price_net_ * 100) / 100,
        currency: product.base_price?.currency || "EUR",
        priceCaption: "monthly_from",
      };
    });

const useFormattedShortViewProducts = (products, locale) => {
  const format = useFormatter();
  const t = useTranslations();

  const [formattedProducts, setFormattedProducts] = useState([]);

  useEffect(() => {
    const enrichedProductData = products.map((product) => ({
      ...product,
      priceCaption: t(`${locale}.${product.priceCaption}`),
      priceRaw: product.price,
      price: format.number(product.price, {
        style: "currency",
        currency: product.currency,
      }),
    }));

    setFormattedProducts(enrichedProductData);
  }, [products, format, t, locale]);

  return formattedProducts;
};

const generateDetailViewProductData = (product) => ({
  ...product,
  images:
    product?.images?.data?.map((image) => ({
      id: image.id,
      ...getStrapiAttributes(image),
    })) || [],
  base_price: {
    ...product.base_price,
    taxId: product.base_price?.tax?.data?.id,
    tax: getStrapiAttributes(product.base_price?.tax?.data)
      ? getStrapiAttributes(product.base_price?.tax?.data).value
      : 0,
  },
  manufacturer: {
    ...getStrapiAttributes(product.manufacturer?.data),
    id: product.manufacturer?.data?.id,
  },
  price_modifiers: product.price_modifiers.data
    .map((item) => ({
      id: item.id,
      ...getStrapiAttributes(item),
    }))
    .sort((a, b) => a.timeframe - b.timeframe),
  product_group: {
    ...getStrapiAttributes(product.product_group?.data),
    id: product.product_group?.data?.id,
  },
  specifications: product.specifications.map((specification) => ({
    id: specification.id,
    value: specification.value,
    label:
      getStrapiAttributes(specification.specification.data)?.name ||
      "undefined",
  })),
  specification_sheet: getStrapiAttributes(product.specification_sheet?.data),
  similar_products: generateShortViewProductData(
    product.similar_products?.data || []
  ),
  price_comparison_sheet: Boolean(
    getStrapiAttributes(product.price_comparison_sheet?.data)
  ),
});

export {
  generateShortViewProductData,
  useFormattedShortViewProducts,
  generateDetailViewProductData,
  calculateProductPrice,
};
