import theme from "./theme";

const defaultSeo = {
  titleTemplate: `%s`,
  additionalMetaTags: [
    {
      name: "viewport",
      content: "initial-scale=1, width=device-width",
    },
    {
      name: "theme-color",
      content: theme.palette.primary.main,
    },
  ],
  additionalLinkTags: [
    {
      rel: "icon",
      href: "/favicon.ico",
    },
    {
      rel: "apple-touch-icon",
      href: "/apple-touch-icon.png",
      sizes: "180x180",
    },
    {
      rel: "icon",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
    {
      rel: "icon",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "manifest",
      href: "/site.manifest.json",
    },
  ],
};

export default defaultSeo;
