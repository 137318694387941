import T from "prop-types";
import { useState } from "react";
import { useTranslations } from "next-intl";
import { useFormikContext } from "formik";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "components/MUI";
import { Body } from "components/atoms/Typography";
import theme from "config/theme";

import { CheckboxComponent } from "./Checkbox";
import { getValueByName } from "./helpers";

function CheckboxGroup({
  margin,
  label,
  name,
  validateOnChange,
  options,
  showError,
}) {
  const t = useTranslations("form.validation");
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext();

  const [ownState, setOwnState] = useState(getValueByName(name, values));

  const handleChange = (value) => {
    const newState = ownState.includes(value)
      ? ownState.filter((item) => item !== value)
      : [...ownState, value];

    setOwnState(newState);

    if (validateOnChange) {
      setFieldValue(name, newState);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name);
    if (!validateOnChange) {
      setFieldValue(name, ownState);
    }
  };

  const hasError = Boolean(
    getValueByName(name, touched) && getValueByName(name, errors)
  );

  return (
    <FormControl
      margin={margin}
      component="fieldset"
      variant="standard"
      fullWidth
      name={name}
    >
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            onChange={() => handleChange(option.value)}
            onBlur={handleBlur}
            control={
              <CheckboxComponent checked={ownState.includes(option.value)} />
            }
            label={
              <Body
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                }}
              >
                {option.label}
              </Body>
            }
            sx={{ my: theme.spacing(0.5) }}
          />
        ))}
      </FormGroup>
      {hasError && showError && (
        <FormHelperText>{t(`${getValueByName(name, errors)}`)}</FormHelperText>
      )}
    </FormControl>
  );
}

CheckboxGroup.propTypes = {
  label: T.string,
  name: T.string.isRequired,
  validateOnChange: T.bool,
  margin: T.string,
  showError: T.bool,
  options: T.arrayOf(
    T.shape({ value: T.oneOfType([T.string, T.number]), label: T.string })
  ).isRequired,
};

CheckboxGroup.defaultProps = {
  label: null,
  validateOnChange: false,
  showError: true,
  margin: "dense",
};

export default CheckboxGroup;
