import { useTranslations } from "next-intl";

import { Container, useMediaQuery } from "components/MUI";
import { H1 } from "components/atoms/Typography";
import theme from "config/theme";

import { HeaderBox } from "./HeaderVariants.styles";
import SearchTextField from "./SearchTextField";

function SearchHeader() {
  const t = useTranslations();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <HeaderBox component="header" paddingBottom={10}>
      <Container maxWidth="xl">
        <H1
          align={isSmall ? "left" : "center"}
          sx={{ pt: theme.spacing(8), pb: theme.spacing(6) }}
        >
          {t("header.search.h1")}
        </H1>
        <SearchTextField />
      </Container>
    </HeaderBox>
  );
}

export default SearchHeader;
