import T from "prop-types";
import { useFormatter } from "next-intl";
import dynamic from "next/dynamic";

import { Container, Grid } from "components/MUI";
import { H1, H4 } from "components/atoms/Typography";
import theme from "config/theme";

import {
  HeaderBox,
  HeaderBoxWithImage,
  HeaderBoxImage,
  HeaderBoxImageGrid,
  blogHeaderStyles,
} from "./HeaderVariants.styles";

const Markdown = dynamic(() => import("components/atoms/Markdown"), {
  ssr: false,
});

function BlogHeader({ dynamicData, variant }) {
  const format = useFormatter();

  if (variant === "blog") {
    return (
      <HeaderBox component="header" id="header" paddingBottom={10}>
        <Container maxWidth="xl">
          <H1 sx={{ pt: theme.spacing(8) }}>{dynamicData.title}</H1>
          <H4
            as="p"
            sx={{
              mt: theme.spacing(3),
              maxWidth: "860px",
              color: theme.palette.primary.main,
            }}
          >
            {dynamicData.description}
          </H4>
        </Container>
      </HeaderBox>
    );
  }

  // Blog detail header
  return (
    <HeaderBox component="header" id="header" paddingBottom={10}>
      <Container maxWidth="xl">
        <Grid container spacing={theme.spacing(2)}>
          <Grid item md={6} xs={12} order={{ md: 0, xs: 1 }} zIndex={1}>
            <H1>{dynamicData.title}</H1>
            <Markdown
              styles={blogHeaderStyles}
              text={dynamicData.description}
            />
            <span>{format.dateTime(dynamicData.date, "short")}</span>
          </Grid>
          <HeaderBoxImageGrid item md={6} xs={12} order={{ md: 1, xs: 0 }}>
            <HeaderBoxWithImage>
              <HeaderBoxImage
                src={dynamicData.image.url}
                priority
                loading="eager"
                fill
                alt={dynamicData.image.alt}
                sizes="(max-width: 768px) 400px,
              (max-width: 1200px) 600px,
              600px"
              />
            </HeaderBoxWithImage>
          </HeaderBoxImageGrid>
        </Grid>
      </Container>
    </HeaderBox>
  );
}

BlogHeader.propTypes = {
  dynamicData: T.shape({
    title: T.string,
    description: T.string,
    date: T.number,
    image: T.shape({
      url: T.string,
      alt: T.string,
    }),
  }).isRequired,
  variant: T.string.isRequired,
};

BlogHeader.defaultProps = {};

export default BlogHeader;
