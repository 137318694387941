/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProducts } from "api/product";
import { generateShortViewProductData } from "helpers/products";
import { LOADING_STATE } from "store/store.constants";
import {
  getManufacturerFilter,
  getProductGroupFilter,
  getSearchQuery,
  getSortBy,
} from "store/ui/ui.selectors";

const initialState = {
  data: [],
  pagination: null,
  loadingState: {
    status: LOADING_STATE.INITIAL,
    error: null,
  },
};

const getQueryParams = (
  manufacturers = [],
  productGroup = [],
  sortByOption = null,
  searchQuery = ""
) => {
  const query = {};

  if (manufacturers.length > 0) {
    query[`filters[$and][0][manufacturer][id][$in]`] = manufacturers;
  }

  if (productGroup.length > 0) {
    query[`filters[$and][0][product_group][id][$in]`] = productGroup;
  }

  if (searchQuery) {
    query["filters[$or][1][name][$containsi]"] = searchQuery;
    query["filters[$or][2][short_description][$containsi]"] = searchQuery;
    query["filters[$or][3][article_number][$containsi]"] = searchQuery;
    query["filters[$or][4][manufacturer][name][$containsi]"] = searchQuery;
    query["filters[$or][5][product_group][name][$containsi]"] = searchQuery;
  }

  if (sortByOption) {
    query["sort[0]"] = sortByOption;
  }

  return query;
};

export const getProductsBySettings = createAsyncThunk(
  "products/getProductsBySettings",
  async ({ searchQuery, manufacturers, sortOption, locale }, { getState }) => {
    const manufacturerFilter =
      manufacturers || getManufacturerFilter(getState());
    const productGroupFilter = getProductGroupFilter(getState());
    const sortByOption = sortOption || getSortBy(getState());
    const searchValue = searchQuery || getSearchQuery(getState());

    const queryParams = getQueryParams(
      manufacturerFilter,
      productGroupFilter,
      sortByOption,
      searchValue
    );

    const query = {
      ...queryParams,
      "populate[images]": "*",
      "populate[product_group][fields][0]": "name",
      "populate[product_group][fields][1]": "slug",
      "populate[base_price]": "*",
      "populate[price_modifiers]": "*",
      "populate[manufacturer][fields][0]": "name",
      "populate[manufacturer][fields][1]": "id",
      locale,
    };

    const response = await getProducts({ ...query });

    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, { payload }) => {
      state.data = payload;
    },
    resetSearch: (state) => {
      state.data = initialState.data;
      state.pagination = initialState.pagination;
      state.loadingState = initialState.loadingState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsBySettings.pending, (state) => {
        state.loadingState = {
          status: LOADING_STATE.LOADING,
          error: null,
        };
      })
      .addCase(getProductsBySettings.rejected, (state) => {
        state.loadingState = {
          status: LOADING_STATE.ERROR,
          error: "error_get_products",
        };
      })
      .addCase(getProductsBySettings.fulfilled, (state, action) => {
        state.data = generateShortViewProductData(action.payload.data);
        state.pagination = action.payload?.meta?.pagination || null;
        state.loadingState = {
          status: LOADING_STATE.SUCCESS,
        };
      });
  },
});

export const { resetSearch, setProducts } = productsSlice.actions;

export default productsSlice.reducer;
