import T from "prop-types";

import { SvgIcon } from "components/MUI";
import theme from "config/theme";

import CUSTOM_ICONS from "./CustomIcon.config";

function CustomIcon({ name, fill, title, ...props }) {
  const icon = CUSTOM_ICONS[name];

  if (!icon) {
    return null;
  }

  return (
    <SvgIcon data-test-id={`icon-${name}`} viewBox={icon.viewBox} {...props}>
      {title && <title>{title}</title>}
      {icon.path(fill)}
    </SvgIcon>
  );
}

CustomIcon.propTypes = {
  name: T.oneOf(Object.keys(CUSTOM_ICONS)).isRequired,
  fill: T.string,
  title: T.string,
};

CustomIcon.defaultProps = {
  fill: theme.palette.custom.black,
  title: null,
};

export default CustomIcon;
