import T from "prop-types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BreadcrumbJsonLd } from "next-seo";

import Link from "components/Link";
import { Breadcrumbs as MuiBreadCrumbs } from "components/MUI";
import theme from "config/theme";
import { PUBLIC_URL } from "config/system";

function Breadcrumbs({ breadcrumbs, sx }) {
  if (
    !breadcrumbs ||
    !Array.isArray(breadcrumbs) ||
    breadcrumbs?.length === 0
  ) {
    return null;
  }

  return (
    <>
      <BreadcrumbJsonLd
        itemListElements={breadcrumbs.map((breadcrumb, key) => ({
          position: key + 1,
          name: breadcrumb.title,
          item: `${PUBLIC_URL}/${breadcrumb.href}`,
        }))}
      />
      <MuiBreadCrumbs
        data-test-id="breadcrumbs-wrapper"
        separator={<NavigateNextIcon fontSize="0.9rem" />}
        aria-label="breadcrumb"
        sx={sx}
      >
        {breadcrumbs.map((breadcrumb) => (
          <Link key={breadcrumb.key} underline="hover" href={breadcrumb.href}>
            {breadcrumb.title}
          </Link>
        ))}
      </MuiBreadCrumbs>
    </>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: T.arrayOf(
    T.shape({ key: T.string, href: T.string, title: T.string })
  ).isRequired,
  sx: T.shape({}),
};

Breadcrumbs.defaultProps = {
  sx: { py: theme.spacing(3) },
};

export default Breadcrumbs;
