const SUPPORTED_LANGUAGES = [
  {
    locale: "de",
    name: "Deutsch",
  },
];

export const NUMBER_FORMATS = {
  dateTime: {
    short: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  },
  number: {
    precise: {
      maximumFractionDigits: 5,
    },
  },
  list: {
    enumeration: {
      style: "long",
      type: "conjunction",
    },
  },
};

const formatSlug = (slug, locale, defaultLocale) =>
  locale === defaultLocale ? `/${slug}` : `/${locale}/${slug}`;

export const localizePath = (pageContext) => {
  const { locale, defaultLocale, localizations, slug } = pageContext;
  const localeFound = localizations.find((a) => a.locale === locale);
  if (localeFound) return formatSlug(localeFound.slug, locale, defaultLocale);
  return formatSlug(slug, locale, defaultLocale);
};

const getLocalizedPaths = (pageContext) => {
  const { locales, defaultLocale, localizations, slug } = pageContext;

  const paths = locales.map((locale) => {
    if (localizations.length === 0)
      return {
        locale,
        href: formatSlug(slug, locale, defaultLocale),
      };
    return {
      locale,
      href: localizePath({ ...pageContext, locale }),
    };
  });
  return paths;
};

export { SUPPORTED_LANGUAGES, getLocalizedPaths };
