import { createTheme } from "components/MUI";

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1275,
    },
  },
  palette: {
    primary: {
      main: "#323F53",
      contrast: "#fff",
    },
    secondary: {
      main: "#fff",
      contrast: "#323F53",
    },
    error: {
      main: "#D82323",
    },
    success: {
      main: "#059625",
    },
    custom: {
      brightBlue: "#305BF1",
      darkGrey: "#566983",
      midGrey: "#AFB5C2",
      lightGrey: "#E5E8EC",
      black: "#18253A",
      gradient: "linear-gradient(121.72deg, #F7F9FA 13.46%, #EFF1F6 73.35%)",
    },
  },
  shadows: {
    0: "none",
    1: "3px 9px 7px rgba(0, 0, 0, 0.1)",
    2: "3px 0px 0px rgba(0, 0, 0, 0.1)",
    3: "0px 4px 19px rgba(50, 63, 83, 0.3)",
    4: "10px 10px 100px rgba(155, 155, 155, 0.15)",
  },
  typography: {
    allVariants: {
      fontFamily: ["'Inter'", "sans-serif"].join(","),
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: "normal",
    },
    h1: {
      fontSize: "3rem",
      lineHeight: "140%",
      fontWeight: 600,
      letterSpacing: "0.02em",
      "@media (max-width:600px)": {
        fontSize: "2rem", // 32px
        lineHeight: "3rem",
      },
    },
    h2: {
      fontSize: "2rem",
      lineHeight: "3rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "1.125rem", // 18px
        lineHeight: "2rem",
      },
    },
    h3: {
      fontSize: "1.25rem",
      lineHeight: "1.55rem",
      fontWeight: 700,
      "@media (max-width:600px)": {
        fontSize: "1.25rem", // 20px
        lineHeight: "1.55rem",
      },
      "&.faq-question-heading": {
        lineHeight: "1.8rem",
        marginRight: "1rem",
        "@media (max-width:600px)": {
          fontSize: "1.2rem", // 16px
          lineHeight: "1.7rem",
        },
      },
    },
    h4: {
      fontSize: "1.125rem",
      lineHeight: "1.688rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "1rem", // 24px
        lineHeight: "1.3rem",
      },
    },
    body: {
      fontSize: "1rem",
      lineHeight: "1.605rem",
      fontWeight: 400,
      color: "#323F53",
    },
    bodyBold: {
      fontSize: "1rem",
      lineHeight: "1.605rem",
      fontWeight: 700,
      letterSpacing: "0.02rem",
      color: "#323F53",
    },
    bodySemiBold: {
      fontSize: "1rem",
      lineHeight: "1.605rem",
      fontWeight: 500,
    },
    small: {
      fontSize: "0.875rem",
      lineHeight: "1.7rem",
      fontWeight: 400,
      color: "#323F53",
      "@media (max-width:600px)": {
        fontSize: "0.75rem", // 12px
        lineHeight: "1.5rem",
      },
    },
    smallBold: {
      fontSize: "0.875rem",
      lineHeight: "1.063rem",
      fontWeight: 500,
      color: "#566983",
    },
    caption: {
      fontSize: "0.75rem",
      lineHeight: "0.908rem",
      fontWeight: 400,
      letterSpacing: "0.02rem",
      color: "#323F53",
    },
    captionBold: {
      fontSize: "0.75rem",
      lineHeight: "0.908rem",
      fontWeight: 700,
      letterSpacing: "0.02rem",
      color: "#566983",
    },
    logo: {
      fontWeight: 500,
      letterSpacing: "0.163rem",
      fontSize: "1.625rem",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `/* Inter */
        @font-face {
          font-family: "Inter";
          src: local("Inter"), url('/fonts/Inter-VariableFont_slnt_wght.ttf') format("truetype-variations");
          font-weight: 100 900;
          font-display: swap;
        }

        html {
          -webkit-font-smoothing: auto;
          scroll-padding-top: 4rem;
          scroll-behavior: auto;
        }

        @supports not ( -moz-appearance:none ){
          html {
            scroll-behavior: smooth;
          }
        }

        html, body {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          padding: 0;
          color: #18253A;
        }

        :root {
          --swiper-theme-color: #323F53 !important;
          --swiper-pagination-bottom: 0px !important;
        }

        .swiper-slide {
          margin-bottom: 2.5rem;
        }
      `,
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          background: "transparent",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "none",
          transition: "none",
          color: "#323F53",
          fontSize: "0.875rem",
          "&.Mui-disabled": {
            color: "#AFB5C2",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        sizeMedium: {
          marginTop: "5px",
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          right: "1rem",
          fontSize: "0.7rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:hover": {
            borderBottom: 0,
          },
          "label + &": {
            marginTop: "1.5rem !important",
          },
          "&.Mui-error": {
            input: {
              borderColor: "#D82323",
            },
          },
          select: {
            borderRadius: "1px",
            position: "relative",
            fontSize: "0.75rem",
            border: "0.5px solid #323F53",
            width: "100%",
            padding: "0.8rem 1rem 1rem 1rem",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            background: "#fff",
            "&:focus": {
              background: "#fff",
            },
            "&.Mui-disabled": {
              borderColor: "#AFB5C2",
            },
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color",
            },
          },
          input: {
            borderRadius: "1px",
            position: "relative",
            fontSize: "0.75rem",
            border: "0.5px solid #323F53",
            width: "100%",
            padding: "1rem",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            background: "#fff",
            "&.Mui-disabled": {
              borderColor: "#AFB5C2",
            },
            "&::placeholder": {
              color: "#AFB5C2",
              opacity: 1,
              fontSize: "0.75rem",
            },
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color",
            },
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&::before": {
            display: "none",
          },
          borderBottom: "0.5px solid #566983",
          "&:last-of-type": {
            borderRadius: 0,
          },
          "&:first-of-type": {
            borderRadius: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "1rem 0",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0 0 1rem 0",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "> caption": {
            textAlign: "center",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#323F53",
          "&.blog_table_cell": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            "&:last-child": {
              borderRight: 0,
            },
          },
        },
        head: {
          borderBottom: 0,
          fontWeight: 700,
          fontSize: "1.25rem",
          "&.blog_table_cell": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            "&:last-child": {
              borderRight: 0,
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child": {
            "& .MuiTableCell-body": {
              borderBottom: 0,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "2px",
          textTransform: "unset",
          fontWeight: 500,
        },
        text: {
          color: "#333F53",
          fontWeight: 400,
        },
        outlined: {
          color: "#333F53",
        },
        textSecondary: {
          color: "#305BF1",
          fontWeight: 600,
        },
        containedPrimary: {
          backgroundColor: "#305BF1",
          color: "#fff",
          "&:hover, &:focus": {
            backgroundColor: "#1F38F2",
            color: "#fff",
          },
          "&:disabled": {
            backgroundColor: "#AFB5C2",
            color: "#fff",
          },
        },
        outlinedPrimary: {
          borderColor: "#305BF1",
          color: "#305BF1",
          "&:hover": {
            borderColor: "#1F38F2",
            boxShadow: "inset 0px 0px 0px 1px #1F38F2",
            backgroundColor: "transparent",
          },
        },
        containedSecondary: {
          backgroundColor: "#fff",
          color: "#305BF1",
          "&:hover, &:focus": {
            backgroundColor: "#fff",
            backgroundImage:
              "linear-gradient(121.72deg, #F7F9FA 13.46%, #EFF1F6 73.35%)",
          },
        },
        outlinedSecondary: {
          borderColor: "#fff",
          color: "#fff",
          "&:hover": {
            borderColor: "#fff",
            boxShadow: "inset 0px 0px 0px 1px #fff",
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: "#333F53",
          backgroundColor: "#fff",
          boxShadow: "none",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(24, 37, 58, 0.5)",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          border: "1px solid #000",
          padding: "48px",
          color: "#18253A",
          background:
            "linear-gradient(121.72deg, #F7F9FA 13.46%, #EFF1F6 73.35%)",
          "@media (max-width:600px)": {
            padding: "16px",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "#18253A",
        },
      },
    },
  },
});

export default theme;
