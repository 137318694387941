import T from "prop-types";
import { CacheProvider } from "@emotion/react";
import { NextIntlClientProvider } from "next-intl";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import theme from "config/theme";
import createEmotionCache from "config/createEmotionCache";
import { DEFAULT_LOCALE, PUBLIC_URL } from "config/system";
import store, { persistor } from "store/store";
import defaultSeo from "config/defaultSEO";
import { ThemeProvider, CssBaseline } from "components/MUI";
import Layout from "components/Layout";
import { NUMBER_FORMATS } from "config/localisation";

// Client-side cache shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();

  // Defines the canonical url
  const canonicalUrl = `${PUBLIC_URL}${
    router.locale !== DEFAULT_LOCALE ? `/${router.locale}` : ""
  }${router.asPath === "/" ? "" : router.asPath}`.split("?")[0];

  // Defines the language alternates based on set locales
  const languageAlternates = pageProps.locales
    ? pageProps.locales.map((loc) => ({
        hrefLang: loc,
        href:
          loc === DEFAULT_LOCALE
            ? `${PUBLIC_URL}${router.asPath === "/" ? "" : router.asPath}`
            : `${PUBLIC_URL}/${loc}${
                router.asPath === "/" ? "" : router.asPath
              }`,
      }))
    : null;

  return (
    <CacheProvider value={emotionCache}>
      <NextIntlClientProvider
        formats={NUMBER_FORMATS}
        messages={pageProps.messages}
        timeZone="Europe/Berlin"
        locale="de"
      >
        <DefaultSeo
          {...defaultSeo}
          languageAlternates={languageAlternates}
          canonical={canonicalUrl}
          openGraph={{
            type: "website",
            url: PUBLIC_URL,
            locale: router.locale,
            title: "default title",
            description: "",
            images: [
              {
                url: `${PUBLIC_URL}/LaboRent-OG.png`,
                width: 1200,
                height: 630,
                alt: "LaboRent - Laborgeräte mieten statt kaufen",
              },
            ],
          }}
        />
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <CssBaseline />
            <Layout
              navigationData={pageProps.navigationData}
              manufacturerData={pageProps.manufacturerData}
              pageHeaderData={pageProps?.pageHeaderData}
            >
              <PersistGate loading={null} persistor={persistor}>
                {() => <Component {...pageProps} />}
              </PersistGate>
            </Layout>
          </Provider>
        </ThemeProvider>
      </NextIntlClientProvider>
    </CacheProvider>
  );
}

MyApp.propTypes = {
  Component: T.elementType.isRequired,
  // eslint-disable-next-line react/require-default-props
  emotionCache: T.shape({}),
  pageProps: T.shape({
    messages: T.shape({}),
    locales: T.arrayOf(T.string),
    navigationData: T.arrayOf(T.shape({})),
    manufacturerData: T.arrayOf(T.shape({})),
    pageHeaderData: T.shape({}),
  }).isRequired,
};
