import T from "prop-types";
import { Typography } from "components/MUI";

const typographyPropTypes = {
  children: T.node.isRequired,
};

function H1({ children, ...props }) {
  return (
    <Typography variant="h1" component="h1" {...props}>
      {children}
    </Typography>
  );
}

H1.propTypes = { ...typographyPropTypes };

function H2({ children, ...props }) {
  return (
    <Typography variant="h2" {...props} component="h2">
      {children}
    </Typography>
  );
}

H2.propTypes = { ...typographyPropTypes };

function H3({ children, ...props }) {
  return (
    <Typography variant="h3" {...props} component="h3">
      {children}
    </Typography>
  );
}

H3.propTypes = { ...typographyPropTypes };

function H4({ children, ...props }) {
  return (
    <Typography variant="h4" {...props} component="h4">
      {children}
    </Typography>
  );
}

H4.propTypes = { ...typographyPropTypes };

function Body({ children, ...props }) {
  return (
    <Typography variant="body" {...props}>
      {children}
    </Typography>
  );
}

Body.propTypes = { ...typographyPropTypes };

function BodyBold({ children, ...props }) {
  return (
    <Typography variant="bodyBold" {...props}>
      {children}
    </Typography>
  );
}

BodyBold.propTypes = { ...typographyPropTypes };

function BodySemiBold({ children, ...props }) {
  return (
    <Typography variant="bodySemiBold" {...props}>
      {children}
    </Typography>
  );
}

BodySemiBold.propTypes = { ...typographyPropTypes };

function Small({ children, ...props }) {
  return (
    <Typography variant="small" {...props}>
      {children}
    </Typography>
  );
}

Small.propTypes = { ...typographyPropTypes };

function SmallBold({ children, ...props }) {
  return (
    <Typography variant="smallBold" {...props}>
      {children}
    </Typography>
  );
}

SmallBold.propTypes = { ...typographyPropTypes };

function Caption({ children, ...props }) {
  return (
    <Typography variant="caption" {...props}>
      {children}
    </Typography>
  );
}

Caption.propTypes = { ...typographyPropTypes };

function CaptionBold({ children, ...props }) {
  return (
    <Typography variant="captionBold" {...props}>
      {children}
    </Typography>
  );
}

CaptionBold.propTypes = { ...typographyPropTypes };

function Text({ children, ...props }) {
  return <Typography {...props}>{children}</Typography>;
}

Text.propTypes = { ...typographyPropTypes };

function LogoTypography({ children, ...props }) {
  return (
    <Typography variant="logo" {...props}>
      {children}
    </Typography>
  );
}

LogoTypography.propTypes = { ...typographyPropTypes };

export {
  H1,
  H2,
  H3,
  H4,
  Body,
  BodyBold,
  BodySemiBold,
  Small,
  SmallBold,
  Caption,
  CaptionBold,
  Text,
  LogoTypography,
};
