import axios from "axios";

const createNewsletterIntent = (body) =>
  axios({
    method: "post",
    url: "/api/newsletter_signup",
    data: { data: body },
  })
    .then((response) => response?.data)
    .catch((err) => {
      if (err.response?.status === 409) {
        throw new Error(err.response?.data?.message);
      } else {
        throw new Error("error_submit_newsletter_intent");
      }
    });

const createPriceComparisonDownloadIntent = (body) =>
  axios({
    method: "post",
    url: "/api/price_comparison",
    data: { data: body },
  })
    .then((response) => response?.data)
    .catch((err) => {
      if (err.response?.status === 409) {
        throw new Error(err.response?.data?.message);
      } else {
        throw new Error("error_submit_price_comparison_download_intent");
      }
    });

export { createNewsletterIntent, createPriceComparisonDownloadIntent };
