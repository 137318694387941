const SORT_OPTION = {
  price_asc: "lowest_price_net_:asc",
  price_desc: "lowest_price_net_:desc",
};

const SORT_OPTIONS = [SORT_OPTION.price_asc, SORT_OPTION.price_desc];
const DEFAULT_ITEMS_PER_PAGE = 100;

const OPERATOR_NAME = "LaboRent";

export { SORT_OPTIONS, DEFAULT_ITEMS_PER_PAGE, SORT_OPTION, OPERATOR_NAME };
