import Link from "components/Link";

const markdownTheme = {
  li: {
    props: {
      style: { margin: "0 0 0.6rem 0" },
    },
  },
  a: {
    component: Link,
    props: {
      target: "_blank",
      style: {
        fontWeight: 600,
      },
    },
  },
};

export default markdownTheme;
