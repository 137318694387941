import { Formik, Form as FormikForm } from "formik";
import T from "prop-types";

function Form({
  initialValues,
  onSubmit,
  validationSchema,
  validateOnMount,
  children,
  id,
  ...rest
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount={validateOnMount}
      {...rest}
    >
      {() => <FormikForm id={id}>{children}</FormikForm>}
    </Formik>
  );
}

Form.propTypes = {
  id: T.string,
  initialValues: T.shape({}).isRequired,
  children: T.node.isRequired,
  onSubmit: T.func.isRequired,
  validationSchema: T.shape({}).isRequired,
  validateOnMount: T.bool,
};

Form.defaultProps = {
  validateOnMount: true,
  id: "",
};

export default Form;
