import { AppBar, Button, ListItemButton, styled } from "components/MUI";

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "customStyle",
})(({ customStyle }) => ({
  background:
    customStyle === "bg" &&
    "linear-gradient(0deg, rgba(234, 244, 255, 0.3), rgba(234, 244, 255, 0.3)), url(/images/header_background.png)",
}));

const StyledMenuButton = styled(Button)(() => ({
  fontSize: "0.875rem",
  width: "max-content",
  padding: 0,
  ":hover,:focus,:active": {
    backgroundColor: "inherit",
    textDecoration: "underline",
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  "& span": {
    fontSize: "0.875rem",
  },
}));

export { StyledMenuButton, StyledListItemButton, StyledAppBar };
