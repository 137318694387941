import { useEffect, useState } from "react";

const useShouldShowBackground = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const shouldShowBackground = scrollPosition === 0;

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", updatePosition);

    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return shouldShowBackground;
};

export default useShouldShowBackground;
