import { useState } from "react";
import T from "prop-types";
import { useTranslations } from "next-intl";
import { useFormikContext } from "formik";

import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "components/MUI";
import { Small } from "components/atoms/Typography";

import { getValueByName } from "./helpers";
import { CustomCheckedIcon, CustomIcon } from "./Checkbox.styles";

function CheckboxComponent(props) {
  return (
    <MuiCheckbox
      disableRipple
      checkedIcon={<CustomCheckedIcon />}
      icon={<CustomIcon />}
      {...props}
    />
  );
}

export { CheckboxComponent };

function Checkbox({ name, validateOnChange, label, margin }) {
  const t = useTranslations("form.validation");
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext();

  const [ownState, setOwnState] = useState(getValueByName(name, values));

  const handleChange = (e) => {
    setOwnState(e.target.checked);

    if (validateOnChange) {
      setFieldValue(name, e.target.checked);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name);
    if (!validateOnChange) {
      setFieldValue(name, ownState);
    }
  };

  const hasError = Boolean(
    getValueByName(name, touched) && getValueByName(name, errors)
  );

  return (
    <FormControl margin={margin} error={hasError} fullWidth>
      <FormControlLabel
        onChange={handleChange}
        onBlur={handleBlur}
        control={<CheckboxComponent checked={ownState} name={name} />}
        label={
          <Small
            sx={{ display: "flex", alignItems: "center", textAlign: "left" }}
          >
            {label}
          </Small>
        }
      />
      {hasError && (
        <FormHelperText>{t(`${getValueByName(name, errors)}`)}</FormHelperText>
      )}
    </FormControl>
  );
}

Checkbox.propTypes = {
  name: T.string.isRequired,
  validateOnChange: T.bool,
  margin: T.string,
  label: T.oneOfType([T.string, T.node]).isRequired,
};

Checkbox.defaultProps = {
  validateOnChange: false,
  margin: "dense",
};

export default Checkbox;
